import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow, Tooltip,
} from "@mui/material";
import { ThumbsDown, ThumbsUp } from "phosphor-react";
import { getColor } from "theme/colors";
import "./style.scss";
import { StyledText } from "components/Atoms/StyledText/StyledText";
import { StyledChips } from "components/Atoms/StyledChips/StyledChips";
import React, { useCallback, useEffect, useState } from "react";
import { BatchFeedback } from "../BatchFeedback";
import { taxonomyValueType } from "../TaxonomyVersion/TaxonomyVersion";
import { useIntl } from "react-intl";
import { ResponseBatch } from "utils/helpers/respones.helper";
import { useSendBatchFeedback } from "store/PredictBatchStore";
import { PROCESS_STATE } from "store/UserStore";
import { capitalizeFirstLetter } from "utils/helpers/app.helper";

interface BatchResultsTableProps {
  taxonomy: taxonomyValueType;
  data: ResponseBatch[];
  batchId: string;
}

export const BatchResultsTable = ({
  taxonomy,
  data,
  batchId,
}: BatchResultsTableProps) => {
  const rowsPerPage = 10;
  const intl = useIntl();
  const [page, setPage] = useState(0);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState(
    data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  );
  const [thumbDownOpen, setthumbDownOpen] = useState<
    Record<string, { open: boolean; id?: number; clicked: boolean }>
  >({});

  const [thumbUpOpen, setThumbUpOpen] = useState<
    Record<string, { open: boolean; id?: number; clicked: boolean }>
  >({});

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const { sendFeedback, processFeedback } = useSendBatchFeedback();

  useEffect(() => {
    const updatedRows = data.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setRows(updatedRows);
  }, [page, data]);

  return (
    <div>
      <Table sx={{ minWidth: 700 }} aria-label="batchResults">
        <TableHead>
          <TableRow>
            <TableCell colSpan={6}>
              <StyledText variant="body-large" color="grey50">
                {intl.formatMessage({ id: "batch.table.question.column" })}
              </StyledText>
            </TableCell>
            <TableCell colSpan={2}>
              <StyledText variant="body-large" color="grey50" align="center">
                {intl.formatMessage({ id: "batch.table.category.column" })}
              </StyledText>
            </TableCell>
            <TableCell colSpan={1}>
              <StyledText variant="body-large" color="grey50" align="center">
                {intl.formatMessage({ id: "batch.table.confidence.column" })}
              </StyledText>
            </TableCell>
            <TableCell colSpan={1}>
              <Tooltip
                  placement="top"
                  title={intl.formatMessage({id: "feedback.disclaimer"})}>
                  <div>
                    <StyledText variant="body-large" color="grey50" align="center">
                      {intl.formatMessage({ id: "batch.table.feedback.column" })}
                    </StyledText>
                  </div>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={6}>
                  <StyledText variant="body-medium">
                    {row.input_text}
                  </StyledText>
                </TableCell>
                <TableCell colSpan={2}>
                  <div className="chipCategoryContainer">
                    <StyledChips
                      labelId={capitalizeFirstLetter(row.prediction)}
                      className="chipCategoryWidth"
                      toolTipText={capitalizeFirstLetter(row.mapped_prediction)}
                    />
                  </div>
                </TableCell>
                <TableCell colSpan={1}>
                  <StyledText variant="body-medium" align="center">
                    {row.probability}
                  </StyledText>
                </TableCell>
                <TableCell colSpan={1}>
                  <div className="thumbsContainer">
                    {row.feedback_is_positive ? (
                      <>
                        <div className={"thumbContainer thumbContainerClicked"}>
                          <ThumbsUp
                            size={23}
                            color={getColor("green")}
                            weight="fill"
                          />
                        </div>
                        <div
                          className={"thumbContainer thumbContainerDisabled"}
                        >
                          <ThumbsDown
                            size={23}
                            color={getColor("red")}
                            weight="fill"
                          />
                        </div>
                      </>
                    ) : !row.feedback_is_positive &&
                      row.feedback_classification?.length ? (
                      <>
                        <div
                          className={"thumbContainer thumbContainerDisabled"}
                        >
                          <ThumbsUp
                            size={23}
                            color={getColor("green")}
                            weight="fill"
                          />
                        </div>
                        <div className={"thumbContainer thumbContainerClicked"}>
                          <ThumbsDown
                            size={23}
                            color={getColor("red")}
                            weight="fill"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={"thumbContainer hoverElement"}>
                          <ThumbsUp
                            size={23}
                            color={getColor("green")}
                            weight="fill"
                            onClick={() => {
                              if (
                                thumbUpOpen[row.ruuid] &&
                                thumbUpOpen[row.ruuid].clicked
                              ) {
                              } else if (
                                !thumbDownOpen[row.ruuid] ||
                                !thumbDownOpen[row.ruuid].clicked
                              ) {
                                sendFeedback(
                                  true,
                                  "",
                                  "",
                                  row.ruuid,
                                  batchId,
                                  taxonomy
                                );
                                setThumbUpOpen((prevState) => ({
                                  ...prevState,
                                  [row.ruuid]: {
                                    open: true,
                                    id: index,
                                    clicked: true,
                                  },
                                }));
                                setthumbDownOpen((prevState) => ({
                                  ...prevState,
                                  [row.ruuid]: {
                                    open: false,
                                    id: index,
                                    clicked: false,
                                  },
                                }));
                                setTimeout(() => {
                                  setThumbUpOpen((prevState) => ({
                                    ...prevState,
                                    [row.ruuid]: {
                                      open: false,
                                      id: index,
                                      clicked: true,
                                    },
                                  }));
                                }, 1000);
                                if (
                                  processFeedback.state === PROCESS_STATE.FAILED
                                ) {
                                  setError(true);
                                  setTimeout(() => {
                                    setError(false);
                                  }, 1000);
                                  setThumbUpOpen((prevState) => ({
                                    ...prevState,
                                    [row.ruuid]: {
                                      open: false,
                                      id: index,
                                      clicked: false,
                                    },
                                  }));
                                }
                              }
                            }}
                          />
                        </div>
                        <div className={"thumbContainer hoverElement"}>
                          <ThumbsDown
                            size={23}
                            color={getColor("red")}
                            weight="fill"
                            onClick={() => {
                              if (
                                thumbDownOpen[row.ruuid] &&
                                thumbDownOpen[row.ruuid].clicked
                              ) {
                              } else if (
                                !thumbUpOpen[row.ruuid] ||
                                !thumbUpOpen[row.ruuid].clicked
                              ) {
                                setthumbDownOpen((prevState) => ({
                                  ...prevState,
                                  [row.ruuid]: {
                                    open: prevState[row.ruuid]
                                      ? !prevState[row.ruuid].open
                                      : true,
                                    id: index,
                                    clicked: false,
                                  },
                                }));
                                setThumbUpOpen((prevState) => ({
                                  ...prevState,
                                  [row.ruuid]: {
                                    open: false,
                                    id: index,
                                    clicked: false,
                                  },
                                }));
                              }
                            }}
                          />
                        </div>
                      </>
                    )}

                    {thumbUpOpen[row.ruuid] && thumbUpOpen[row.ruuid].open && (
                      <div className="thankYouContainer">
                        <StyledText>
                          {intl.formatMessage({ id: "thankYou.label" })}
                        </StyledText>
                      </div>
                    )}
                    {error && (
                      <div className="errorContainer">
                        <StyledText color="red">
                          {intl.formatMessage({ id: "feedback.error" })}
                        </StyledText>
                      </div>
                    )}
                  </div>
                </TableCell>
              </TableRow>
              {thumbDownOpen[row.ruuid]?.open && (
                <TableRow>
                  <TableCell colSpan={12} className="feedbackRow">
                    <BatchFeedback
                      currentCategory={row.prediction}
                      taxonomy={taxonomy}
                      onCancel={() =>
                        setthumbDownOpen((prevState) => ({
                          ...prevState,
                          [row.ruuid]: {
                            open: false,
                            id: index,
                            clicked: false,
                          },
                        }))
                      }
                      onSubmit={(e) => {
                        sendFeedback(
                          false,
                          e.category,
                          e.comment,
                          row.ruuid,
                          batchId,
                          taxonomy
                        );
                        setthumbDownOpen((prevState) => ({
                          ...prevState,
                          [row.ruuid]: {
                            open: false,
                            id: index,
                            clicked: true,
                          },
                        }));
                        setThumbUpOpen((prevState) => ({
                          ...prevState,
                          [row.ruuid]: {
                            open: true,
                            id: index,
                            clicked: false,
                          },
                        }));
                        setTimeout(() => {
                          setThumbUpOpen((prevState) => ({
                            ...prevState,
                            [row.ruuid]: {
                              open: false,
                              id: index,
                              clicked: false,
                            },
                          }));
                        }, 1000);
                        if (processFeedback.state === PROCESS_STATE.FAILED) {
                          setError(true);
                          setTimeout(() => {
                            setError(false);
                          }, 1000);
                          setthumbDownOpen((prevState) => ({
                            ...prevState,
                            [row.ruuid]: {
                              open: false,
                              id: index,
                              clicked: false,
                            },
                          }));
                          setThumbUpOpen((prevState) => ({
                            ...prevState,
                            [row.ruuid]: {
                              open: false,
                              id: index,
                              clicked: false,
                            },
                          }));
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </div>
  );
};
