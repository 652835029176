import { StyledText } from "components/Atoms/StyledText/StyledText";
import { StyledChips } from "components/Atoms/StyledChips/StyledChips";
import "./styles.scss";
import { DotsSixVertical, X } from "phosphor-react";
import { InputChips } from "components/Atoms/InputChips";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  useCategoriesStore,
  useMapLegacy,
  useMapRevised,
} from "store/CategoriesStore";
import { ChangesSavedConfirm } from "../ChangesSavedConfirm";
import { capitalizeFirstLetter } from "utils/helpers/app.helper";
interface DnDCustomizeCategoriesProps {
  taxonomy: "original" | "revised";
}

export const DnDCustomizeCategories = ({
  taxonomy,
}: DnDCustomizeCategoriesProps) => {
  const { mapLegacy } = useMapLegacy();
  const { mapRevised } = useMapRevised();
  const {
    legacyCategories,
    revisedCategories,
    mapLegacy_process,
    mapRevised_process,
  } = useCategoriesStore();

  var groupBy = function (toGroup: Array<any>, key: string) {
    return toGroup.reduce(function (e, x) {
      (e[x[key]] = e[x[key]] || []).push(x);
      return e;
    }, {});
  };

  const original = Object.entries(legacyCategories).filter(
    (category) => category[1] === null
  );
  const revised = Object.entries(revisedCategories).filter(
    (category) => category[1] === null
  );

  const originalCustom = Object.entries(legacyCategories).filter(
    (category) => category[1] !== null
  );
  const revisedCustom = Object.entries(revisedCategories).filter(
    (category) => category[1] !== null
  );

  const resultOriginal = originalCustom
    .map((item) => {
      var final: Array<{ category: string; name: string | null }> = [];
      final = [{ category: item[0], name: item[1] }].concat(final);
      return final;
    })
    .flat(1);
  const resultRevised = revisedCustom
    .map((item) => {
      var final: Array<{ category: string; name: string | null }> = [];
      final = [{ category: item[0], name: item[1] }].concat(final);
      return final;
    })
    .flat(1);

  const customOriginal: any = Object.entries(groupBy(resultOriginal, "name"));
  const customRevised: any = Object.entries(groupBy(resultRevised, "name"));

  const sortByOrder = (data: any[]): any[] => {
    return data.sort((a: any, b: any) => {
      if (a["order"] < b["order"]) {
        return -1;
      } else if (a["order"] > b["order"]) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const getDefaultName = (currentName: string, category?: any) => {
    var name: string = currentName;
    if (currentName === "customCategories") {
      name = category ? capitalizeFirstLetter(category) : "";
    } else {
      if (taxonomy === "original") {
        const groupOriginal = customOriginal.filter(
          (custom: any) => custom[0] === currentName
        );
        const originalTaxSorted = [
          { category: "knowledge", order: 0 },
          { category: "comprehension", order: 1 },
          { category: "application", order: 2 },
          { category: "analysis", order: 3 },
          { category: "synthesis", order: 4 },
          { category: "evaluation", order: 5 },
        ];
        const newCategoryOriginal = category
          ? [
              {
                ...originalTaxSorted.filter(
                  (cat) => cat.category === category
                )[0],
                name: "",
              },
            ]
          : [];
        const sortedGroupOriginal = sortByOrder(
          groupOriginal[0][1]
            .map((item: any) => {
              var res: any = [];
              res = [
                {
                  ...item,
                  ...originalTaxSorted.filter(
                    (cat) => cat.category === item.category
                  )[0],
                },
              ].concat(res);

              return res;
            })
            .map((arr: any) => arr[0])
            .concat(newCategoryOriginal)
        );
        name = sortedGroupOriginal
          .map((obj: any) => capitalizeFirstLetter(obj.category))
          .join("/");
      } else if (taxonomy === "revised") {
        const groupRevised = customRevised.filter(
          (custom: any) => custom[0] === currentName
        );
        const revisedTaxSorted = [
          { category: "remember", order: 0 },
          { category: "understand", order: 1 },
          { category: "apply", order: 2 },
          { category: "analyze", order: 3 },
          { category: "evaluate", order: 4 },
          { category: "create", order: 5 },
        ];
        const newCategoryRevised = category
          ? [
              {
                ...revisedTaxSorted.filter(
                  (cat) => cat.category === category
                )[0],
                name: "",
              },
            ]
          : [];
        const sortedGroupRevised = sortByOrder(
          groupRevised[0][1]
            .map((item: any) => {
              var res: any = [];
              res = [
                {
                  ...item,
                  ...revisedTaxSorted.filter(
                    (cat) => cat.category === item.category
                  )[0],
                },
              ].concat(res);

              return res;
            })
            .map((arr: any) => arr[0])
            .concat(newCategoryRevised)
        );
        name = sortedGroupRevised
          .map((obj: any) => capitalizeFirstLetter(obj.category))
          .join("/");
      }
    }
    return name;
  };

  const handleOnDragEnd = (e: any) => {
    if (taxonomy === "original") {
      if (e.destination.droppableId === "customCategories") {
        Object.keys(legacyCategories).forEach((item) => {
          if (item === e.draggableId) {
            legacyCategories[item] = capitalizeFirstLetter(e.draggableId);
            setTimeout(() => {
              mapLegacy(legacyCategories);
            }, 200);
          }
        });
      } else {
        const defaultName = getDefaultName(e.destination.droppableId);
        Object.keys(legacyCategories).forEach((item) => {
          if (
            legacyCategories[item] === e.destination.droppableId ||
            item === e.draggableId
          ) {
            if (defaultName === e.destination.droppableId) {
              const finalName = getDefaultName(
                e.destination.droppableId,
                e.draggableId
              );
              legacyCategories[item] = finalName;
              setTimeout(() => {
                mapLegacy(legacyCategories);
              }, 200);
            } else {
              legacyCategories[item] = e.destination.droppableId;
              setTimeout(() => {
                mapLegacy(legacyCategories);
              }, 200);
            }
          }
        });
      }
    } else {
      if (e.destination.droppableId === "customCategories") {
        Object.keys(revisedCategories).forEach((item) => {
          if (item === e.draggableId) {
            revisedCategories[item] = capitalizeFirstLetter(e.draggableId);
            setTimeout(() => {
              mapRevised(revisedCategories);
            }, 200);
          }
        });
      } else {
        const defaultName = getDefaultName(e.destination.droppableId);
        Object.keys(revisedCategories).forEach((item) => {
          if (
            revisedCategories[item] === e.destination.droppableId ||
            item === e.draggableId
          ) {
            if (defaultName === e.destination.droppableId) {
              const finalName = getDefaultName(
                e.destination.droppableId,
                e.draggableId
              );
              revisedCategories[item] = finalName;
              setTimeout(() => {
                mapRevised(revisedCategories);
              }, 200);
            } else {
              revisedCategories[item] = e.destination.droppableId;
              setTimeout(() => {
                mapRevised(revisedCategories);
              }, 200);
            }
          }
        });
      }
    }
  };

  const onRemoveCategory = (categoryName: string, groupName: string) => {
    const name = getDefaultName(groupName);
    if (taxonomy === "original") {
      Object.keys(legacyCategories).forEach((item) => {
        if (item === categoryName) {
          legacyCategories[item] = null;
        } else if (legacyCategories[item] === name) {
          const finalName: string = groupName
            .replace(`/${capitalizeFirstLetter(categoryName)}`, "")
            .replace(`${capitalizeFirstLetter(categoryName)}/`, "");
          legacyCategories[item] = finalName;
        }
        setTimeout(() => {
          mapLegacy(legacyCategories);
        }, 200);
      });
    } else {
      Object.keys(revisedCategories).forEach((item) => {
        if (item === categoryName) {
          revisedCategories[item] = null;
        } else if (revisedCategories[item] === name) {
          const finalName: string = groupName
            .replace(`/${capitalizeFirstLetter(categoryName)}`, "")
            .replace(`${capitalizeFirstLetter(categoryName)}/`, "");
          revisedCategories[item] = finalName;
        }
        setTimeout(() => {
          mapLegacy(legacyCategories);
        }, 200);
      });
    }
  };

  const onChangeName = (value: string, categories: Array<any>) => {
    if (taxonomy === "original") {
      categories.forEach((item: any) => {
        legacyCategories[item.category] = value;
        setTimeout(() => {
          mapLegacy(legacyCategories);
        }, 200);
      });
    } else if (taxonomy === "revised") {
      categories.forEach((item: any) => {
        revisedCategories[item.category] = value;
        setTimeout(() => {
          mapRevised(revisedCategories);
        }, 200);
      });
    }
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className="DnDContainer">
        <Droppable droppableId="categories">
          {(provided) => (
            <div
              className="availableContainer"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <StyledText variant="title-small">
                Available Categories
              </StyledText>
              {taxonomy === "original"
                ? original.map((category, index) => (
                    <div className="chipContainer" key={category[0]}>
                      <Draggable
                        draggableId={category[0]}
                        key={category[0]}
                        index={index}
                      >
                        {(provided) => (
                          <StyledChips
                            labelId={
                              category[0].charAt(0).toUpperCase() +
                              category[0].slice(1)
                            }
                            leftIcon={<DotsSixVertical size={16} />}
                            className="chipWidth"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            customRef={provided.innerRef}
                          />
                        )}
                      </Draggable>
                      {/* <Info size={18} color={getColor("grey50")} /> */}
                    </div>
                  ))
                : revised.map((category, index) => (
                    <div className="chipContainer" key={category[0]}>
                      <Draggable
                        draggableId={category[0]}
                        key={category[0]}
                        index={index}
                      >
                        {(provided) => (
                          <StyledChips
                            labelId={
                              category[0].charAt(0).toUpperCase() +
                              category[0].slice(1)
                            }
                            leftIcon={<DotsSixVertical size={16} />}
                            className="chipWidth"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            customRef={provided.innerRef}
                          />
                        )}
                      </Draggable>
                      {/* <Info size={18} color={getColor("grey50")} /> */}
                    </div>
                  ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <div className="customizedContainer">
          <div className="customCategoriesTitle">
            <StyledText variant="title-small">Customized categories</StyledText>
            <ChangesSavedConfirm
              state={
                taxonomy === "original"
                  ? mapLegacy_process.state
                  : mapRevised_process.state
              }
            />
          </div>
          {taxonomy === "original" && customOriginal.length > 0
            ? customOriginal.map((customCategory: any, index: any) => (
                <div className="groupingContainer" key={index + "l"}>
                  <Droppable droppableId={customCategory[0]}>
                    {(provided) => (
                      <div
                        className="droppingSide"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {customCategory[1].map((category: any) => (
                          <StyledChips
                            labelId={
                              category.category.charAt(0).toUpperCase() +
                              category.category.slice(1)
                            }
                            rightIcon={
                              <X
                                size={16}
                                className={"hoverElement"}
                                onClick={() =>
                                  onRemoveCategory(
                                    category.category,
                                    customCategory[0]
                                  )
                                }
                              />
                            }
                            className="chipWidth"
                            key={category.category}
                          />
                        ))}
                        <div className="droppingCategory">
                          <StyledText>Drag a category here</StyledText>
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <div className="nameContainer">
                    <InputChips
                      defaultValue={customCategory[0]}
                      onChange={(e) => onChangeName(e, customCategory[1])}
                      id={customCategory[1]}
                    />
                  </div>
                </div>
              ))
            : taxonomy === "revised" &&
              customRevised.length > 0 &&
              customRevised.map((customCategory: any, index: any) => (
                <div className="groupingContainer" key={index + "r"}>
                  <Droppable droppableId={customCategory[0]}>
                    {(provided) => (
                      <div
                        className="droppingSide"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {customCategory[1].map((category: any) => (
                          <StyledChips
                            labelId={
                              category.category.charAt(0).toUpperCase() +
                              category.category.slice(1)
                            }
                            rightIcon={
                              <X
                                size={16}
                                className={"hoverElement"}
                                onClick={() =>
                                  onRemoveCategory(
                                    category.category,
                                    customCategory[0]
                                  )
                                }
                              />
                            }
                            className="chipWidth"
                            key={category.category}
                          />
                        ))}
                        <div className="droppingCategory">
                          <StyledText>Drag a category here</StyledText>
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <div className="nameContainer">
                    <InputChips
                      defaultValue={customCategory[0]}
                      onChange={(e) => onChangeName(e, customCategory[1])}
                      id={customCategory[1]}
                    />
                  </div>
                </div>
              ))}

          <div className="groupingContainer">
            <Droppable droppableId="customCategories">
              {(provided) => (
                <div
                  className="droppingSide"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <div className="droppingCategory">
                    <StyledText>Drag a category here</StyledText>
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className="nameContainer">
              <InputChips disabled id="customCategories" />
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};
