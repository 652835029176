import React from "react"
import { Elevations } from "components/Atoms/Elevations/Elevations"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import { useIntl } from "react-intl"
import "./styles.scss"

interface DetailsCardTierProps {
  number: string | number
  description: string
}

export const DetailsCardTier = ({
  description,
  number,
}: DetailsCardTierProps) => {
  const intl = useIntl()
  return (
    <Elevations variant="rectangle-3" className="detail-card-container">
      <h1 className="secondary100">{number}</h1>
      <StyledText variant="body-large" color="natural-black" align="center">
      {intl.formatMessage({ id: description })}
      </StyledText>
    </Elevations>
  )
}
