import axios, { InternalAxiosRequestConfig } from "axios";
import { useUserStore } from "store/UserStore";

const EnfluxFormData = axios.create({
  baseURL: process.env.REACT_APP_APIHOST,
  responseType: "json",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

EnfluxFormData.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    const { access_token } = useUserStore.getState().session;
    if (access_token && access_token !== "" && config.headers) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error);
  }
);

EnfluxFormData.interceptors.response.use(undefined, function (error: any) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // console.log(error.response.data.detail, Promise.reject(error));
  return Promise.reject(error);
});

export default EnfluxFormData;

// export default abstract class AIAPI {
//     private static _instance: AxiosInstance;

//     public static client(): AxiosInstance {
//         if (!AIAPI._instance) {
//             AIAPI._instance = axios.create({
//                 baseURL: process.env.REACT_APP_APIHOST
//             });
//         }
//         return AIAPI._instance
//     }

//     public static setAuth(access_token?: string) {
//         AIAPI.client().interceptors.request.clear()
//         if (access_token) {
//             AIAPI.client().interceptors.request.use((config) => {
//                 config.headers.Authorization = `Bearer ${access_token}`
//                 return config
//             })
//         }
//     }
// }
