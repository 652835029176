import InputFile from "components/Atoms/InputFile";
import { StyledText } from "components/Atoms/StyledText/StyledText";
import { FileArrowDown, Info } from "phosphor-react";
import { useIntl } from "react-intl";
import { getColor } from "theme/colors";
import "./style.scss";
import { StyledButton } from "components/Atoms/StyledButton/StyledButton";
import { useEffect, useState } from "react";
import { FileSpecsModal } from "components/Molecules/FileSpecsModal";
import { BatchResultsTable } from "components/Molecules/BatchResultsTable";
import { taxonomyValueType } from "components/Molecules/TaxonomyVersion/TaxonomyVersion";
import {
  usePredictBatchLegacy,
  usePredictBatchRevised,
} from "store/PredictBatchStore";
import { PROCESS_STATE, useUserStore } from "store/UserStore";
import {
  useTierReferralMe,
  useTierReferralsStore,
} from "store/TierReferralsStore";
import { API_HOST } from "../../constants";
import { DownloadFile } from "services/dowloadServices";

interface BloomClassifierBatchProps {
  taxonomy: taxonomyValueType;
  isDisabled?: boolean;
}

export const BloomsClassifierBatchContainer = ({
  taxonomy,
  isDisabled,
}: BloomClassifierBatchProps) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [revisedFile, setRevisedFile] = useState<File>();
  const [legacyFile, setLegacyFile] = useState<File>();
  const { predictionsLeft } = useTierReferralsStore().tierDataUser;
  const { access_token } = useUserStore.getState().session;
  const [error, setError] = useState("");
  const { getTierDataUser } = useTierReferralMe();
  const {
    predictBatchLegacy,
    process: processBatchLegacy,
    clean: cleanBatchLegacy,
    legacyPredictions,
    legacyBatchId,
    processLegacyResults,
    processLegacyStatus,
  } = usePredictBatchLegacy();
  const {
    predictBatchRevised,
    process: processBatchRevised,
    clean: cleanBatchRevised,
    revisedPredictions,
    revisedBatchId,
    processRevisedResults,
    processRevisedStatus,
  } = usePredictBatchRevised();

  const isLoading = Boolean(
    ((processBatchRevised.state === PROCESS_STATE.EXECUTING ||
      processRevisedResults.state === PROCESS_STATE.EXECUTING ||
      processRevisedStatus.state === PROCESS_STATE.EXECUTING) &&
      taxonomy === "revised") ||
      ((processBatchLegacy.state === PROCESS_STATE.EXECUTING ||
        processLegacyResults.state === PROCESS_STATE.EXECUTING ||
        processLegacyStatus.state === PROCESS_STATE.EXECUTING) &&
        taxonomy === "original")
  );

  const downloadUrl =
    taxonomy === "original"
      ? `${API_HOST}/predict/batch/file/${legacyBatchId}`
      : `${API_HOST}/predict/batch/file/${revisedBatchId}`;

  useEffect(() => {
    setError(
      taxonomy === "original"
        ? processBatchLegacy?.data
          ? processBatchLegacy?.data?.detail
          : processLegacyResults?.data
          ? processLegacyResults?.data?.detail
          : processLegacyStatus?.data
          ? processLegacyStatus?.data.detail
          : ""
        : taxonomy === "revised"
        ? processBatchRevised?.data
          ? processBatchRevised?.data?.detail
          : processRevisedResults?.data
          ? processRevisedResults?.data?.detail
          : processRevisedStatus?.data
          ? processRevisedStatus?.data.detail
          : ""
        : ""
    );
  }, [
    processBatchLegacy.data,
    processLegacyResults.data,
    processLegacyStatus.data,
    processBatchRevised.data,
    processRevisedResults.data,
    processRevisedStatus.data,
    taxonomy,
  ]);

  return (
    <>
      <div className="uploadFileTitle">
        <StyledText variant="title-medium">
          {intl.formatMessage({ id: "bloom.classifier.upload.file" })}
        </StyledText>
        <div
          style={{ display: "flex" }}
          className={"hoverElement"}
          onClick={() => setOpen(true)}
        >
          <Info
            size={17}
            color={getColor("secondary100")}
            style={{ marginRight: "0.3rem" }}
          />
          <StyledText color="secondary100" variant="label-medium" underline>
            {intl.formatMessage({ id: "file.specs.modal.button" })}
          </StyledText>
        </div>
        <FileSpecsModal open={open} onClose={() => setOpen(false)} />
      </div>
      <InputFile
        name="batch"
        taxonomy={taxonomy}
        onFileChange={(data) => {
          data && taxonomy === "original"
            ? setLegacyFile(data)
            : data && taxonomy === "revised" && setRevisedFile(data);
        }}
        onInvalidFile={(e) => setError(e)}
        onReplace={(e) => {
          if (e && taxonomy === "original") {
            cleanBatchLegacy();
          } else if (e && taxonomy === "revised") {
            cleanBatchRevised();
          }
        }}
        disabled={Boolean(predictionsLeft === 0)}
      />
      {(legacyPredictions && taxonomy === "original") ||
      (revisedPredictions && taxonomy === "revised") ? (
        <div>
          <div className="downloadButtonBatch ">
            <StyledText variant="body-large">
              {intl.formatMessage({ id: "classifier.batch.download.note" })}
            </StyledText>
            <StyledButton
              buttonVariant="contained"
              size="large"
              title="classifier.batch.download"
              onClick={() =>
                DownloadFile(downloadUrl, access_token!, "batch_results.xlsx")
              }
              withLeftIcon={
                <FileArrowDown size={20} color={getColor("natural-white")} />
              }
            />
          </div>
          <BatchResultsTable
            taxonomy={taxonomy}
            data={
              taxonomy === "original" ? legacyPredictions : revisedPredictions
            }
            batchId={taxonomy === "original" ? legacyBatchId : revisedBatchId}
          />
        </div>
      ) : (
        <div>
          <div className="inputButtonBatch">
            <StyledText variant="label-small" color="red">
              {predictionsLeft === 0
                ? "Predictions unavailable"
                : error.length
                ? error === "No more predictions, exceeded usage"
                  ? "Insufficient predictions"
                  : error
                : ""}
            </StyledText>
          </div>
          <div className="inputButtonBatch">
            <StyledButton
              buttonVariant="contained"
              size="large"
              title="button.classify"
              isDisabled={
                predictionsLeft === 0
                  ? true
                  : (Boolean(revisedFile || legacyFile) &&
                      Boolean(!error.length)) ||
                    isDisabled
                  ? false
                  : true
              }
              isLoading={isLoading}
              onClick={() => {
                let revisedData, legacyData;
                if (revisedFile && taxonomy === "revised") {
                  revisedData = new FormData();
                  revisedData.append("input_file", revisedFile);
                  predictBatchRevised(revisedData, getTierDataUser);
                }
                if (legacyFile) {
                  legacyData = new FormData();
                  legacyData.append("input_file", legacyFile);
                  predictBatchLegacy(legacyData, getTierDataUser);
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
