import { StyledText } from "components/Atoms/StyledText/StyledText"
import { PROCESS_STATE, useUserLogin } from "../../store/UserStore"
import "./style.scss"
import {
  LoginForm,
  LoginFormModel,
} from "components/Molecules/Forms/LoginForm/LoginForm"
import { useCallback } from "react"
import Img from "components/Atoms/Img"
import { ROUTES } from "../../constants"
import { useIntl } from "react-intl"
import { StyledButton } from "components/Atoms/StyledButton/StyledButton"
import { useNavigate } from "react-router-dom"
import {
  useTierReferralMe,
  useTierReferralInformation,
} from "store/TierReferralsStore"
import { useGetCategories } from "store/CategoriesStore"

export const LoginContainer = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { login, process } = useUserLogin()
  const { getTierDataUser } = useTierReferralMe()
  const { getCategories } = useGetCategories()
  const { getTierDataInformation } = useTierReferralInformation()

  const handleSucessLogin = useCallback(async () => {
    getTierDataUser()
    getTierDataInformation()
    getCategories("legacy")
    getCategories("revised")
  }, [getCategories, getTierDataUser, getTierDataInformation])

  const handleLogIn = useCallback(
    async (data: LoginFormModel) => {
      login(data, handleSucessLogin)
    },
    [login, handleSucessLogin]
  )

  return (
    <main className="login-container">
      <Img src={"/images/main_logo.png"} alt="logo" className="imgP-2" />

      <StyledText align="center" variant="title-large" color="primary100">
        {intl.formatMessage({ id: "Login.title" })}
      </StyledText>
      {/* {process.state === PROCESS_STATE.FAILED ? (
        <StyledText color="red">
          {intl.formatMessage({ id: "incorrect.username.password" })}
        </StyledText>
      ) : null} */}
      <LoginForm
        onClick={handleLogIn}
        isLoading={process.state === PROCESS_STATE.EXECUTING}
      />
      <StyledButton
        buttonVariant="text"
        size="medium"
        title="screen.createAccount"
        onClick={() => navigate(ROUTES.REGISTER)}
      />
    </main>
  )
}
