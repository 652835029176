import { StyledText } from "components/Atoms/StyledText/StyledText"
import { DetailsCardTier } from "components/Molecules/DetailsCardTier/DetailsCardTier"
import { ShareTierCard } from "components/Molecules/ShareTierCard/ShareTierCard"
import { TierCards } from "components/Molecules/TierCards/TierCards"
import {
  useTierReferralMe,
  useTierReferralsStore,
} from "store/TierReferralsStore"
import { useUserStore } from "store/UserStore"
import { useIntl } from "react-intl"
import "./styles.scss"
import { GenericContainer } from "components/Molecules/GenericContainer"
import { useEffect } from "react"

export const TiersReferralsContainer = () => {
  const intl = useIntl()
  const { referral_id } = useUserStore((state) => state.userData)
  const { tierLevel, predictionsLeft, referralCount, isUnlimited } = useTierReferralsStore(
    (state) => state.tierDataUser
  )
  const { getTierDataUser } = useTierReferralMe()

  useEffect(() => {
    getTierDataUser()
  }, [getTierDataUser])

  const checkTierActive = (strToCheck: string) => {
    if (strToCheck?.includes("Essentials")) {
      return 1
    } else if (strToCheck?.includes("Plus")) {
      return 2
    } else return 3
  }
  return (
    <>
      {predictionsLeft && tierLevel && (
        <GenericContainer className="elevation-referral-tier-container">
          <div>
            <StyledText
              variant="title-large"
              color="natural-black"
              align="center">
              {intl.formatMessage({ id: "tiers" })}
            </StyledText>
            <StyledText
              variant="body-large"
              align="center"
              color="natural-black">
              {intl.formatMessage({ id: "tiers.description" })}
            </StyledText>
          </div>
          {!isUnlimited && (
            <>
              <ShareTierCard referralCode={referral_id as string} />
              <div className="details-card-container">
                <DetailsCardTier
                  description="detail.card.tier.classifications.left"
                  number={predictionsLeft}
                />
                <DetailsCardTier
                  description="detail.card.tier.people.referral"
                  number={referralCount}
                />
              </div>
            </>
          )}
          <TierCards indexActive={checkTierActive(tierLevel)} />
        </GenericContainer>
      )}
    </>
  )
}
