import { StyledText } from "components/Atoms/StyledText/StyledText";
import "./style.scss";
import { GenericContainer } from "components/Molecules/GenericContainer";
import { ArrowSquareOut } from "phosphor-react";
import { getColor } from "theme/colors";
import { StyledTab, TabOption } from "components/Atoms/Tab/StyledTab";
import { useCallback, useEffect, useState } from "react";
import {
  taxonomyValueType,
  TaxonomyVersion,
} from "components/Molecules/TaxonomyVersion/TaxonomyVersion";
import { QuotaCounter } from "components/Molecules/QuotaCounter/QuotaCounter";
import { useTierReferralsStore } from "store/TierReferralsStore";
import { useIntl } from "react-intl";
import { CustomizeCategoriesButton } from "components/Molecules/CustomizeCategoriesButton/CustomizeCategoriesButton";
import { StyledChips } from "components/Atoms/StyledChips/StyledChips";
import { OneByOneClassifierComponent } from "components/Molecules/OneByOneClassifierComponent/OneByOneClassifierComponent";
import { useCategoriesStore } from "store/CategoriesStore";
import {
  capitalizeFirstLetter,
  removeDuplicates,
} from "utils/helpers/app.helper";
import { BloomsClassifierBatchContainer } from "containers/BloomClassifierBatch";
import { StyledButton } from "components/Atoms/StyledButton/StyledButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

type entryType = "text" | "batch";

export const BloomsClassifierContainer = () => {
  const [taxonomy, setTaxonomy] = useState<taxonomyValueType>("original");
  const [entryType, setEntryType] = useState<entryType>("text");
  const [categorieToMap, setCategorieToMap] = useState<(string | null)[][]>();
  const { predictionsLeft, canBatch } = useTierReferralsStore().tierDataUser;
  const intl = useIntl();
  const navigate = useNavigate();

  const tabOptiones: TabOption[] = [
    {
      labelId: "tab.title.onebyone",
      value: "text",
    },
    {
      labelId: "tab.title.bybatch",
      value: "batch",
    },
  ];

  const { revisedCategories, legacyCategories } = useCategoriesStore();

  const categorie =
    taxonomy === "original" ? legacyCategories : revisedCategories;

  const returnCategoriesThatBelongsTo = useCallback(
    (categoryName: string) => {
      var name = "";
      for (let value in categorie) {
        if (
          categorie[value] !== null &&
          categorie[value] === categorie[categoryName]
        )
          name += capitalizeFirstLetter(value) + " - ";
      }
      return (name = name.slice(0, -2));
    },
    [categorie]
  );
  const mappingNewCustomCategories = useCallback(() => {
    const newArray = [];
    for (let categoryName in categorie) {
      newArray.push([
        categorie[categoryName] === null
          ? categoryName
          : categorie[categoryName],
        categorie[categoryName] === null
          ? categoryName
          : returnCategoriesThatBelongsTo(categoryName),
      ]);
    }
    const arrayWithoutDuplicates = removeDuplicates(newArray);
    setCategorieToMap(arrayWithoutDuplicates);
  }, [categorie, returnCategoriesThatBelongsTo]);

  useEffect(() => {
    mappingNewCustomCategories();
  }, [mappingNewCustomCategories]);

  return (
    <GenericContainer className="bodyWMin">
      <div className="bloom-classifier-container">
        <StyledText variant="title-large">
          {intl.formatMessage({ id: "bloom.classifier.title" })}
        </StyledText>
        <StyledText variant="title-medium">
          {intl.formatMessage({ id: "bloom.classifier.subtitle" })}
        </StyledText>
        <div className="bloom-classifier-description">
          <h4 className="natural-black text-left">
            {intl.formatMessage({ id: "bloom.classifier.description.1" })}
            &nbsp;&nbsp;
            <a href={"https://google.com"} target="_blank" rel="noreferrer">
              <StyledText
                className="bloom-classifier-link-text"
                variant="label-medium"
                color="secondary100"
                underline
              >
                {intl.formatMessage({ id: "bloom.classifier.description.2" })}
              </StyledText>
            </a>
            &nbsp;
            <ArrowSquareOut
              size={11.25}
              color={getColor("secondary100")}
              cursor={"pointer"}
            />
          </h4>
        </div>

        {canBatch ? (
          <>
            <StyledTab
              fullWidth
              options={tabOptiones}
              currentValue={entryType}
              onChange={() =>
                setEntryType(entryType === "text" ? "batch" : "text")
              }
            />
            <QuotaCounter counter={predictionsLeft!} />

            <div className="category-container">
              <TaxonomyVersion value={taxonomy} onChange={setTaxonomy} />
              <div className="classifier-categories-container">
                <div className="title-categories-container">
                  <StyledText variant="title-medium">
                    {intl.formatMessage({ id: "classifier.categories" })}
                  </StyledText>
                  <CustomizeCategoriesButton />
                </div>
                <div className="chip-categorie-container">
                  {categorieToMap?.map((categorie, index) => {
                    return (
                      <StyledChips
                        toolTipText={
                          categorie[1]?.includes("-")
                            ? categorie[1]
                            : capitalizeFirstLetter(categorie[1]!)
                        }
                        key={index}
                        labelId={capitalizeFirstLetter(categorie[0]!)}
                        className="styled-chip"
                      />
                    );
                  })}
                </div>
                <div className="divider"></div>
                {entryType === "text" ? (
                  <OneByOneClassifierComponent taxonomy={taxonomy} />
                ) : (
                  <BloomsClassifierBatchContainer taxonomy={taxonomy} />
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <StyledTab
              fullWidth
              options={tabOptiones}
              currentValue={entryType}
              onChange={() =>
                setEntryType(entryType === "text" ? "batch" : "text")
              }
            />
            <QuotaCounter counter={predictionsLeft!} />
            {entryType === "text" ? (
              <div className="category-container">
                <TaxonomyVersion value={taxonomy} onChange={setTaxonomy} />
                <div className="classifier-categories-container">
                  <div className="title-categories-container">
                    <StyledText variant="title-medium">
                      {intl.formatMessage({ id: "classifier.categories" })}
                    </StyledText>
                    <CustomizeCategoriesButton />
                  </div>
                  <div className="chip-categorie-container">
                    {categorieToMap?.map((categorie, index) => {
                      return (
                        <StyledChips
                          toolTipText={
                            categorie[1]?.includes("-")
                              ? categorie[1]
                              : capitalizeFirstLetter(categorie[1]!)
                          }
                          key={index}
                          labelId={capitalizeFirstLetter(categorie[0]!)}
                          className="styled-chip"
                        />
                      );
                    })}
                  </div>
                  <div className="divider"></div>
                  <OneByOneClassifierComponent taxonomy={taxonomy} />
                </div>
              </div>
            ) : (
              <div className="batchNotAllowed">
                <StyledText
                  variant="body-large"
                  align="center"
                  color="grey50"
                  className="marginItems"
                >
                  {intl.formatMessage({ id: "batch.access.denied" })}
                </StyledText>
                <StyledText
                  align="center"
                  variant="body-large"
                  color="grey50"
                  className="marginItems"
                >
                  {intl.formatMessage({ id: "batch.access.levelUp" })}
                </StyledText>
                <div className="marginItems">
                  <StyledButton
                    size="large"
                    buttonVariant="contained"
                    title={"check.tiers.button"}
                    onClick={() => navigate(ROUTES.TIERSANDREFERRALS)}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </GenericContainer>
  );
};
