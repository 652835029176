import Img from "components/Atoms/Img";
import { StyledText } from "components/Atoms/StyledText/StyledText";
import { List, UserCircle } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { getColor } from "theme/colors";
import { useUserStore } from "store/UserStore";
import "./style.scss";
import { DrawerComponent, MenuListI } from "../Drawer/DrawerComponent";
import { ROUTES } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { MenuInformationI, UserMenu } from "../UserMenu";
import { useTierReferralsStore } from "store/TierReferralsStore";
import {
  usePredictLegacyText,
  usePredictRevisedText,
} from "store/PredictTextStore";
import {
  usePredictBatchLegacy,
  usePredictBatchRevised,
} from "store/PredictBatchStore";
const MenuList: MenuListI[] = [
  {
    name: "blooms.classifier",
    link: ROUTES.HOME,
  },
  {
    name: "blooms.customize.title",
    link: ROUTES.CUSTOMIZE,
  },
];

export const Header = () => {
  const { first_name, referral_id } = useUserStore((state) => state.userData);
  const { tierLevel, predictionsLeft, predictionsQuota, canDownload } =
    useTierReferralsStore((state) => state.tierDataUser);
  const { clean: legacyClean } = usePredictLegacyText();
  const { clean: revisedClean } = usePredictRevisedText();
  const { clean: cleanBatchLegacy } = usePredictBatchLegacy();
  const { clean: cleanBatchRevised } = usePredictBatchRevised();
  const createMenuInformation = useMemo(() => {
    const data: MenuInformationI = {
      firstName: first_name ?? "My name",
      referralCode: referral_id ?? "Referral code: error",
      tierLevel: tierLevel ?? "Tier: error",
      predictionsLeft: predictionsLeft ?? 0,
    };
    return data;
  }, [first_name, referral_id, tierLevel, predictionsLeft]);
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { logout } = useUserStore();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const onLogOut = () => {
    logout();
    legacyClean();
    revisedClean();
    cleanBatchLegacy();
    cleanBatchRevised();
  };

  return (
    <div className="header">
      <div>
        <List
          onClick={() => setOpenDrawer(true)}
          size={32}
          color={getColor("primary100")}
          style={{ marginRight: "2rem" }}
          className={"hoverElement"}
        />
        <DrawerComponent
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          menuList={MenuList}
        />
        <Img
          className="main-logo-img"
          onClick={() => navigate(ROUTES.HOME)}
          src={"/images/main_logo.png"}
          height={"40px"}
          width={"183px"}
        />
      </div>
      <div
        className={"userComp"}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => handleClick(e)}
      >
        <UserCircle
          size={32}
          color={getColor("secondary100")}
          style={{ marginRight: "2rem" }}
          weight="fill"
        />
        <StyledText
          variant="body-medium"
          color={"primary100"}
          align="left"
          underline
          className={"hoverElement"}
        >
          {first_name}
        </StyledText>
        <UserMenu
          onLogout={onLogOut}
          data={createMenuInformation}
          id="basic-menu"
          anchorEl={anchorEl}
          tierLevel={tierLevel ? tierLevel : "Essentials"}
          predictionsQuota={predictionsQuota ? predictionsQuota : 1}
          open={open}
          canDownload={canDownload!}
          onClose={(e) => {
            e.stopPropagation();
            setAnchorEl(null);
          }}
        />
      </div>
    </div>
  );
};
