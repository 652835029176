import { InputAdornment, OutlinedInputProps, TextField } from "@mui/material"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import { Eye, EyeClosed } from "phosphor-react"
import { FocusEventHandler, useCallback, useState } from "react"
import { useIntl } from "react-intl"
import "./style.scss"

export interface StyledTextFieldProps {
  label: string
  value?: string
  name?: string
  required?: boolean
  defaultValue?: string
  message?: string
  errorMessage?: string
  highlightError?: boolean
  id: string
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void
  password?: boolean
  withLeftIcon?: React.ReactNode
  withRightIcon?: React.ReactNode
  uppercase?: boolean
  disabled?: boolean
  showMaxlengthCounter?: boolean
  maxLength?: number
  className?: string
  fullWidth?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

export const StyledTextField = ({
  label,
  value,
  name,
  defaultValue,
  required,
  message,
  errorMessage,
  highlightError = true,
  id,
  password,
  onChange: onChangeProps,
  withLeftIcon,
  withRightIcon,
  uppercase,
  disabled,
  maxLength,
  showMaxlengthCounter,
  className,
  fullWidth,
  onBlur,
}: StyledTextFieldProps) => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState(false)
  const [internalCounter, setInternalCounter] = useState(0)

  const Messages = () => {
    return (
      <>
        {Boolean(errorMessage) ? (
          <div className="messages-container">
            <StyledText
              variant="label-small"
              color={disabled ? "grey20" : "red"}
              align="left">
              {intl.formatMessage({ id: errorMessage })}
            </StyledText>
            {Boolean(maxLength && showMaxlengthCounter) && (
              <StyledText
                variant="label-small"
                color={disabled ? "grey20" : "red"}
                align="left">
                {`${internalCounter}/${maxLength}`}
              </StyledText>
            )}
          </div>
        ) : Boolean(message) ? (
          <div className="messages-container">
            <StyledText
              variant="label-small"
              color={disabled ? "grey20" : "natural-black"}
              align="left">
              {message}
            </StyledText>
            {Boolean(maxLength && showMaxlengthCounter) && (
              <StyledText
                variant="label-small"
                color={disabled ? "grey20" : "natural-black"}
                align="left">
                {`${internalCounter}/${maxLength}`}
              </StyledText>
            )}
          </div>
        ) : (
          Boolean(maxLength && showMaxlengthCounter) && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <StyledText
                variant="label-small"
                color={disabled ? "grey20" : "natural-black"}
                align="left">
                {`${internalCounter}/${maxLength}`}
              </StyledText>
            </div>
          )
        )}
      </>
    )
  }

  const onInputOrAreaChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInternalCounter(e?.target?.value?.length || 0)
      if (onChangeProps) {
        onChangeProps(e)
      }
    },
    [onChangeProps]
  )

  return password ? (
    <div className={`input-container ${className ? className : ""}`}>
      <TextField
        InputProps={
          {
            disableUnderline: true,
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => !disabled && setShowPassword(!showPassword)}
                className={!disabled ? "iconClass" : "iconDisabled"}
                style={{ paddingTop: "15px" }}>
                {!showPassword ? <Eye size={23} /> : <EyeClosed size={23} />}
              </InputAdornment>
            ),
          } as Partial<OutlinedInputProps>
        }
        inputProps={{ maxLength: maxLength }}
        label={intl.formatMessage({ id: label })}
        variant="filled"
        value={value}
        name={name}
        id={id}
        defaultValue={defaultValue}
        required={required}
        error={Boolean(highlightError && errorMessage)}
        onChange={onInputOrAreaChange}
        type={!showPassword ? "password" : "text"}
        disabled={disabled}
        fullWidth={fullWidth}
      />
      <Messages />
    </div>
  ) : (
    <div className={`input-container ${className ? className : ""}`}>
      <TextField
        InputProps={
          {
            disableUnderline: true,
            startAdornment: withLeftIcon && (
              <InputAdornment
                position="start"
                className={!disabled ? "iconClass" : "iconDisabled"}
                style={{ paddingTop: "5px" }}>
                {withLeftIcon}
              </InputAdornment>
            ),
            endAdornment: withRightIcon && (
              <InputAdornment
                position="end"
                className={!disabled ? "iconClass" : "iconDisabled"}
                style={
                  withLeftIcon ? { paddingTop: "20px" } : { paddingTop: "15px" }
                }>
                {withRightIcon}
              </InputAdornment>
            ),
          } as Partial<OutlinedInputProps>
        }
        onBlur={onBlur}
        inputProps={{ maxLength: maxLength }}
        label={intl.formatMessage({ id: label })}
        variant="filled"
        value={value}
        name={name}
        id={id}
        fullWidth={fullWidth}
        defaultValue={defaultValue}
        required={required}
        error={Boolean(highlightError && errorMessage)}
        onChange={onInputOrAreaChange}
        disabled={disabled}
      />
      <Messages />
    </div>
  )
}
