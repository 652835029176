import React, { useState } from "react"
import { Elevations } from "components/Atoms/Elevations/Elevations"
import { StyledText } from "components/Atoms/StyledText/StyledText"
import "./styles.scss"
import { CopySimple } from "phosphor-react"
import { getColor } from "theme/colors"
import { useIntl } from "react-intl"
import { useTierReferralsStore } from "store/TierReferralsStore"

interface ShareTierCardProps {
  referralCode: string
}

export const ShareTierCard = ({ referralCode }: ShareTierCardProps) => {
  const { tierLevel } = useTierReferralsStore().tierDataUser
  const intl = useIntl()
  const [copy, setCopy] = useState(false)
  const copyToClipboard = () => {
    setCopy(true)
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_WEBHOST}/signup?referral=${referralCode}`
    )
    setTimeout(() => {
      setCopy(false)
    }, 1000)
  }
  return (
    <Elevations variant="rectangle-3" className="rectangle-container">
      <div className="card-container">
        <div className="tier-number-container">
          <StyledText
            color="natural-black"
            variant="body-medium"
            align="center">
            {intl.formatMessage({ id: "share.tier.you.are.on" })}
          </StyledText>
          <StyledText variant="title-medium" align="center">
              {tierLevel}
          </StyledText>
          <StyledText
              color="natural-black"
              variant="body-medium"
              align="center">
            {intl.formatMessage({ id: "share.tier.tier" })}
          </StyledText>
        </div>

        <div className="description-tier-container">
          <StyledText align="left" variant="title-medium" color="natural-black">
            {intl.formatMessage({ id: "share.tier.description1" })}
          </StyledText>
          <StyledText align="left" variant="body-small" color="natural-black">
            {intl.formatMessage({ id: "share.tier.description2" })}
          </StyledText>
          <div className="referral-code-container" onClick={copyToClipboard}>
            <small className="label primary100 text-left underline">
              {`${process.env.REACT_APP_WEBHOST}/signup?referral=${referralCode}`}
            </small>

            {copy ? (
              <StyledText
                align="left"
                variant="label-large"
                color="natural-black">
                {intl.formatMessage({ id: "share.tier.copied" })}
              </StyledText>
            ) : (
              <CopySimple size={18} color={getColor("primary100")} />
            )}
          </div>
        </div>
      </div>
    </Elevations>
  )
}
