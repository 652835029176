import React, { ChangeEvent, useCallback } from "react";
import { useState } from "react";
import { FileDrop } from "react-file-drop";
import "./styles.scss";
import { StyledText } from "../StyledText/StyledText";
import { CheckCircle, FileArrowUp } from "phosphor-react";
import { getColor } from "theme/colors";
import { bytesToMb } from "utils/helpers/app.helper";
import { useIntl } from "react-intl";
import {
  usePredictBatchLegacy,
  usePredictBatchRevised,
} from "store/PredictBatchStore";
import { taxonomyValueType } from "components/Molecules/TaxonomyVersion/TaxonomyVersion";

export interface FileDimmensions {
  width?: number;
  height?: number;
}

export interface InputFileProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  error?: string;
  label?: string;
  onFileChange?: (file: File) => void;
  onInvalidFile?: (error: string) => void;
  disabled?: boolean;
  fileName?: string;
  onReplace: (e: boolean) => void;
  taxonomy: taxonomyValueType;
}

const InputFile = ({
  name,
  error,
  label,
  onFileChange: onFileChangeProps,
  disabled,
  onInvalidFile,
  fileName: preChargedFile,
  taxonomy,
  onReplace,
}: InputFileProps) => {
  const intl = useIntl();
  const [file, getFile] = useState<File | null>();

  const onFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        e.stopPropagation();
      }
      if (e.target.files) {
        let receivedfile: File | null = e.target.files[0];
        if (receivedfile !== file) {
          onReplace(true);
          try {
            if (
              receivedfile &&
              receivedfile.name.endsWith(".xlsx") &&
              receivedfile.size < 50000000
            ) {
              getFile(receivedfile);
              if (onFileChangeProps) {
                onFileChangeProps(receivedfile);
                onInvalidFile && onInvalidFile("");
              }
            } else {
              getFile(receivedfile);
              onInvalidFile && onInvalidFile("Verify the file specifications");
            }
          } catch (error: any) {
            if (onInvalidFile) {
              onInvalidFile(error);
            }
          }
        }
      }
    },
    [disabled, onFileChangeProps, onInvalidFile, file, onReplace]
  );

  const onDrop = async (files: FileList | null) => {
    if (files) {
      let receivedfile: File = files[0];
      if (receivedfile !== file) {
        onReplace(true);
        try {
          if (
            receivedfile &&
            receivedfile.name.endsWith(".xlsx") &&
            receivedfile.size < 50000000
          ) {
            getFile(receivedfile);
            if (onFileChangeProps) {
              onFileChangeProps(receivedfile);
              onInvalidFile && onInvalidFile("");
            }
          } else {
            getFile(receivedfile);
            onInvalidFile && onInvalidFile("Verify the file specifications");
          }
        } catch (error: any) {
          if (onInvalidFile) {
            onInvalidFile(error);
          }
        }
      }
    }
  };
  const { legacyPredictions } = usePredictBatchLegacy();
  const { revisedPredictions } = usePredictBatchRevised();
  const questionsCount =
    legacyPredictions && taxonomy === "original"
      ? legacyPredictions.length
      : revisedPredictions && taxonomy === "revised"
      ? revisedPredictions.length
      : 0;

  return (
    <div>
      {label && <StyledText>{label}</StyledText>}
      <label className="inputFile" style={disabled ? { opacity: 0.5 } : {}}>
        <input
          type="file"
          className="hiddenInput"
          accept={"xslx"}
          id="fileItem"
          onChange={onFileChange}
          onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            const element = e.target as HTMLInputElement;
            element.value = "";
          }}
          name={name}
          disabled={disabled}
        />
        <FileDrop
          onDrop={disabled ? () => {} : onDrop}
          className={disabled ? "notAllowed dropFile" : "dropFile"}
        >
          {(legacyPredictions && taxonomy === "original") ||
          (revisedPredictions && taxonomy === "revised") ? (
            <>
              <div className="d-flex fileProcessed">
                <div>
                  <div className="d-flex">
                    <CheckCircle
                      weight="fill"
                      size={25}
                      style={{ marginRight: "0.3rem" }}
                      color={getColor("grey100")}
                    />
                    <div>
                      <StyledText color="grey100" variant="title-small">
                        {file?.name}
                      </StyledText>
                      <StyledText color="grey50" variant="label-medium">
                        {`${bytesToMb(file?.size)?.toFixed(3).toString()} Mb`}
                      </StyledText>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    disabled
                      ? "d-flex align-center"
                      : "d-flex hoverElement align-center"
                  }
                >
                  <FileArrowUp
                    size={17}
                    color={getColor("secondary100")}
                    style={{ marginRight: "0.3rem" }}
                  />
                  <StyledText color="secondary100" align="center" underline>
                    {intl.formatMessage({ id: "input.file.replace.file" })}
                  </StyledText>
                </div>
              </div>
              <StyledText
                color="grey50"
                className={"questionsCount"}
              >{`${questionsCount} questions`}</StyledText>
            </>
          ) : (
            <>
              <StyledText color="grey50" align="center">
                {intl.formatMessage({ id: "input.file.instructions" })}
              </StyledText>
              <div
                className={
                  disabled ? "fileTitleUp" : "fileTitleUp hoverElement"
                }
              >
                <FileArrowUp
                  size={17}
                  color={getColor("secondary100")}
                  style={{ marginRight: "0.3rem" }}
                />
                <StyledText color="secondary100" align="center">
                  {file ? file?.name : "Choose file"}
                </StyledText>
              </div>
              <div className="formatContainer">
                <StyledText color="grey50" align="center">
                  {intl.formatMessage({ id: "input.file.specs" })}
                </StyledText>
              </div>
            </>
          )}
        </FileDrop>
      </label>
      {Boolean(error) && <StyledText>{error}</StyledText>}
    </div>
  );
};

export default InputFile;
