import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import {
  predictLegacyBatch,
  predictRevisedBatch,
  getBatchStatus,
  getPredictionsBatch,
  sendFeedback,
} from "services/predicts.service";
import { useEffect, useMemo } from "react";
import { PROCESS_STATE, StoreAsyncProcess } from "./UserStore";
import { taxonomyValueType } from "components/Molecules/TaxonomyVersion/TaxonomyVersion";

interface PredictBatchState {
  batchLegacyId: any;
  batchRevisedId: any;
  batchLegacyState: any;
  batchRevisedState: any;
  batchLegacyPredictions: any;
  batchRevisedPredictions: any;

  batchLegacy_process: StoreAsyncProcess;
  batchLegacy_execute: (
    batchFile: FormData,
    handleSuccess: () => void
  ) => Promise<void>;
  batchLegacy_clear: () => any;

  batchLegacyStatus_process: StoreAsyncProcess;
  batchLegacyStatus_execute: (batchId: string) => Promise<void>;
  batchLegacyStatus_clear: () => any;

  batchLegacyPredictions_process: StoreAsyncProcess;
  batchLegacyPredictions_execute: (batchId: string) => Promise<void>;
  batchLegacyPredictions_clear: () => any;

  batchRevised_process: StoreAsyncProcess;
  batchRevised_execute: (
    batchFile: FormData,
    handleSuccess: () => void
  ) => Promise<void>;
  batchRevised_clear: () => any;

  batchRevisedStatus_process: StoreAsyncProcess;
  batchRevisedStatus_execute: (batchId: string) => Promise<void>;
  batchRevisedStatus_clear: () => any;

  batchRevisedPredictions_process: StoreAsyncProcess;
  batchRevisedPredictions_execute: (batchId: string) => Promise<void>;
  batchRevisedPredictions_clear: () => any;

  clean_batchLegacy: () => void;
  clean_batchRevised: () => void;

  sendBatchFeedback_process: StoreAsyncProcess;
  sendBatchFeedback_execute: (
    is_positive: boolean,
    correct_classification: string,
    comment: string,
    prediction_ruuid: string,
    batchId: string,
    taxonomy: taxonomyValueType
  ) => Promise<void>;
  sendBatchFeedback_clear: () => any;
}

export const usePredictsBatchStore = create<PredictBatchState>()(
  devtools(
    persist(
      (set, get) => ({
        batchLegacyId: "",
        batchRevisedId: "",
        batchLegacyState: "",
        batchRevisedState: "",
        batchLegacyPredictions: null,
        batchRevisedPredictions: null,
        clean_batchLegacy: () =>
          set({
            batchLegacyId: "",
            batchLegacyState: "",
            batchLegacyPredictions: null,
          }),
        clean_batchRevised: () =>
          set({
            batchRevisedId: "",
            batchRevisedState: "",
            batchRevisedPredictions: null,
          }),
        // legacy
        batchLegacy_clear: () =>
          set({
            batchLegacy_process: { state: PROCESS_STATE.IDLE },
          }),
        batchLegacy_execute: async (batchFile, handleSuccess) => {
          set({
            batchLegacy_process: { state: PROCESS_STATE.EXECUTING },
          });
          get().clean_batchLegacy();
          try {
            const { data } = await predictLegacyBatch(batchFile);
            const results = data.data;
            set({ batchLegacyId: results.batch_id });
            get().batchLegacyStatus_execute(results.batch_id);
            set({ batchLegacy_process: { state: PROCESS_STATE.SUCCESS } });
            handleSuccess();
          } catch (error: any) {
            set({
              batchLegacy_process: {
                state: PROCESS_STATE.FAILED,
                data: error.response.data,
              },
            });
          }
        },
        batchLegacy_process: { state: PROCESS_STATE.IDLE },
        batchLegacyStatus_clear: () =>
          set({ batchLegacyStatus_process: { state: PROCESS_STATE.IDLE } }),
        batchLegacyStatus_execute: async (batchId) => {
          set({
            batchLegacyStatus_process: { state: PROCESS_STATE.EXECUTING },
          });
          try {
            const { data } = await getBatchStatus(batchId);
            set({ batchLegacyState: data.batch_status });
            if (data.batch_status !== "finished") {
              get().batchLegacyStatus_execute(batchId);
            } else {
              get().batchLegacyPredictions_execute(batchId);
            }
            set({
              batchLegacyStatus_process: { state: PROCESS_STATE.SUCCESS },
            });
          } catch (error: any) {
            set({
              batchLegacyStatus_process: {
                state: PROCESS_STATE.FAILED,
                data: error.response.data,
              },
            });
          }
        },
        batchLegacyStatus_process: { state: PROCESS_STATE.IDLE },
        //getPredictions
        batchLegacyPredictions_clear: () =>
          set({
            batchLegacyPredictions_process: { state: PROCESS_STATE.IDLE },
          }),
        batchLegacyPredictions_execute: async (batchId) => {
          set({
            batchLegacyPredictions_process: { state: PROCESS_STATE.EXECUTING },
          });
          try {
            const { data } = await getPredictionsBatch(batchId);
            set({
              batchLegacyPredictions: data.data,
            });
            set({
              batchLegacyPredictions_process: { state: PROCESS_STATE.SUCCESS },
            });
          } catch (error: any) {
            set({
              batchLegacyPredictions_process: {
                state: PROCESS_STATE.FAILED,
                data: error.response.data,
              },
            });
          }
        },
        batchLegacyPredictions_process: { state: PROCESS_STATE.IDLE },

        // revised
        batchRevised_clear: () =>
          set({ batchRevised_process: { state: PROCESS_STATE.IDLE } }),
        batchRevised_execute: async (batchFile, handleSuccess) => {
          set({
            batchRevised_process: { state: PROCESS_STATE.EXECUTING },
          });
          get().clean_batchRevised();
          try {
            const { data } = await predictRevisedBatch(batchFile);
            const results = data.data;
            set({ batchRevisedId: results.batch_id });
            get().batchRevisedStatus_execute(results.batch_id);
            set({ batchRevised_process: { state: PROCESS_STATE.SUCCESS } });
            handleSuccess();
          } catch (error: any) {
            set({
              batchRevised_process: {
                state: PROCESS_STATE.FAILED,
                data: error.response.data,
              },
            });
          }
        },
        batchRevised_process: { state: PROCESS_STATE.IDLE },
        batchRevisedStatus_clear: () =>
          set({ batchRevisedStatus_process: { state: PROCESS_STATE.IDLE } }),
        batchRevisedStatus_execute: async (batchId) => {
          set({
            batchRevisedStatus_process: { state: PROCESS_STATE.EXECUTING },
          });
          try {
            const { data } = await getBatchStatus(batchId);
            set({ batchRevisedState: data.batch_status });
            if (data.batch_status !== "finished") {
              get().batchRevisedStatus_execute(batchId);
            } else {
              get().batchRevisedPredictions_execute(batchId);
            }
            set({
              batchRevisedStatus_process: { state: PROCESS_STATE.SUCCESS },
            });
          } catch (error: any) {
            set({
              batchRevisedStatus_process: {
                state: PROCESS_STATE.FAILED,
                data: error.response.data,
              },
            });
          }
        },
        batchRevisedStatus_process: { state: PROCESS_STATE.IDLE },
        //getPredictions
        batchRevisedPredictions_clear: () =>
          set({
            batchRevisedPredictions_process: { state: PROCESS_STATE.IDLE },
          }),
        batchRevisedPredictions_execute: async (batchId) => {
          set({
            batchRevisedPredictions_process: { state: PROCESS_STATE.EXECUTING },
          });
          try {
            const { data } = await getPredictionsBatch(batchId);
            set({ batchRevisedPredictions: data.data });
            set({
              batchRevisedPredictions_process: { state: PROCESS_STATE.SUCCESS },
            });
          } catch (error: any) {
            set({
              batchRevisedPredictions_process: {
                state: PROCESS_STATE.FAILED,
                data: error.response.data,
              },
            });
          }
        },
        batchRevisedPredictions_process: { state: PROCESS_STATE.IDLE },
        sendBatchFeedback_clear: () =>
          set({
            sendBatchFeedback_process: { state: PROCESS_STATE.IDLE },
          }),
        sendBatchFeedback_execute: async (
          is_positive,
          correct_classification,
          comment,
          prediction_ruuid,
          batchId,
          taxonomy
        ) => {
          set({
            sendBatchFeedback_process: { state: PROCESS_STATE.EXECUTING },
          });
          try {
            await sendFeedback(
              is_positive,
              correct_classification,
              comment,
              prediction_ruuid
            );
            if (taxonomy === "original") {
              get().batchLegacyPredictions_execute(batchId);
            } else {
              get().batchRevisedPredictions_execute(batchId);
            }
          } catch (error: any) {
            set({
              sendBatchFeedback_process: {
                state: PROCESS_STATE.FAILED,
                data: error,
              },
            });
          }
        },
        sendBatchFeedback_process: { state: PROCESS_STATE.IDLE },
      }),

      {
        name: "batch-storage",
      }
    )
  )
);

export const usePredictBatchLegacy = () => {
  const {
    clear,
    predictBatchLegacy,
    process,
    clean,
    legacyPredictions,
    legacyBatchId,
    processLegacyResults,
    processLegacyStatus,
  } = usePredictsBatchStore(
    (state) => ({
      predictBatchLegacy: state.batchLegacy_execute,
      process: state.batchLegacy_process,
      clear: state.batchLegacy_clear,
      clean: state.clean_batchLegacy,
      legacyPredictions: state.batchLegacyPredictions,
      legacyBatchId: state.batchLegacyId,
      processLegacyResults: state.batchLegacyPredictions_process,
      processLegacyStatus: state.batchLegacyStatus_process,
    }),
    shallow
  );
  useEffect(
    () => () => {
      clear();
    },
    [clear]
  );
  return useMemo(
    () => ({
      predictBatchLegacy,
      process,
      clean,
      legacyPredictions,
      legacyBatchId,
      processLegacyResults,
      processLegacyStatus,
    }),
    [
      predictBatchLegacy,
      process,
      clean,
      legacyPredictions,
      legacyBatchId,
      processLegacyResults,
      processLegacyStatus,
    ]
  );
};

export const usePredictBatchRevised = () => {
  const {
    clear,
    predictBatchRevised,
    process,
    clean,
    revisedPredictions,
    revisedBatchId,
    processRevisedResults,
    processRevisedStatus,
  } = usePredictsBatchStore(
    (state) => ({
      predictBatchRevised: state.batchRevised_execute,
      process: state.batchRevised_process,
      clear: state.batchRevised_clear,
      clean: state.clean_batchRevised,
      revisedPredictions: state.batchRevisedPredictions,
      revisedBatchId: state.batchRevisedId,
      processRevisedResults: state.batchRevisedPredictions_process,
      processRevisedStatus: state.batchRevisedStatus_process,
    }),
    shallow
  );
  useEffect(
    () => () => {
      clear();
    },
    [clear]
  );
  return useMemo(
    () => ({
      predictBatchRevised,
      process,
      clean,
      revisedPredictions,
      revisedBatchId,
      processRevisedResults,
      processRevisedStatus,
    }),
    [
      predictBatchRevised,
      process,
      clean,
      revisedPredictions,
      revisedBatchId,
      processRevisedResults,
      processRevisedStatus,
    ]
  );
};

export const useSendBatchFeedback = () => {
  const { clearFeedback, sendFeedback, processFeedback } =
    usePredictsBatchStore(
      (state) => ({
        sendFeedback: state.sendBatchFeedback_execute,
        processFeedback: state.sendBatchFeedback_process,
        clearFeedback: state.sendBatchFeedback_clear,
      }),
      shallow
    );
  useEffect(
    () => () => {
      clearFeedback();
    },
    [clearFeedback]
  );
  return useMemo(
    () => ({
      sendFeedback,
      processFeedback,
    }),
    [sendFeedback, processFeedback]
  );
};
