import { StyledTextField } from "components/Atoms/StyledTextField"
import React, { useCallback } from "react"
import { X } from "phosphor-react"
import Form from "components/Atoms/Form"
import { StyledButton } from "components/Atoms/StyledButton/StyledButton"
import { useForm } from "react-hook-form"
import { withControllerInput } from "utils/hocs/withControllerInput"
import { yupResolver } from "@hookform/resolvers/yup"
import { questionText } from "utils/validationSchemas/validationSchema"
import "./styles.scss"

interface InputQuestionCLassifierInputProps {
  handleSubmitQuestion: ({ question }: InputQuestionCLassifierInputForm) => void
  isLoading: boolean
  isDisabled: boolean
  onClickDeleteText?: () => void
}
interface InputQuestionCLassifierInputForm {
  question: string
}

const ControllerInputField = withControllerInput(StyledTextField)
export const InputQuestionClassifierInput = ({
  handleSubmitQuestion,
  isDisabled,
  isLoading,
  onClickDeleteText
}: InputQuestionCLassifierInputProps) => {


  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(questionText),
    defaultValues: { question: "" },
  })

  const submitQuestion = useCallback(
    (data: InputQuestionCLassifierInputForm) => {
      handleSubmitQuestion(data)
    },
    [handleSubmitQuestion]
  )

  const { control, handleSubmit, formState, reset } = methods
  const { isValid, errors } = formState

  const onDeleteText = useCallback(() => {
    reset({ question: "" })
    onClickDeleteText && onClickDeleteText()
  }, [reset, onClickDeleteText])

  return (
    <Form
      methods={methods}
      onSubmit={handleSubmit(submitQuestion)}
      className="form-input-classifier-container">
      <ControllerInputField
        control={control}
        withRightIcon={<X size={26} onClick={onDeleteText} />}
        label="your.text"
        id="question-classifier"
        name="question"
        maxLength={500}
        showMaxlengthCounter
        errorMessage={errors.question?.message as string}
        fullWidth
      />
      <StyledButton
        buttonVariant="contained"
        size="large"
        isSubmbit
        title="button.classify"
        isDisabled={isDisabled || !isValid}
        isLoading={isLoading}
      />
    </Form>
  )
}
